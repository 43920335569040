/** @jsx jsx */
import React, { Fragment, useEffect, useState, useContext } from "react";
import { Container, jsx, Button, Text } from "theme-ui";
import "../../css/account.css";
import { Modal } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { IoIosClose } from "react-icons/io";
import { GoSearch } from "react-icons/go";
import PrimaryLayout from "../../components/layout/primary/primary";
import AccountTopMenu from "../../components/top-menus/account/account-top-menu";
import { validateJwtToken, getJwtTokenRoles } from "../../services/auth-service";
import Pagination from "../../components/pagination/pagination";
import Select from 'react-select';
import { getOrders, setOrderDelivered } from "../../services/order-service";
import { GetMedicalPdf } from "../../services/medical-service";
import { GetAllEmailTemplates, sendEmail } from "../../services/email-service";
import { navigate } from "gatsby";
import { Formik } from "formik";
import DatePicker from "../../components/date-picker/date-picker";
import { CgArrowTopRightR } from "react-icons/cg";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel
} from "react-accessible-accordion";
// Demo styles, see 'Styles' section below for some notes on use.
import "react-accessible-accordion/dist/fancy-example.css";

const AccountOrderPage: React.FunctionComponent = ({ }: any) => {
  if (!validateJwtToken()) return null;

  const [orders, setOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmEmailModal, setShowConfirmEmailModal] = useState(false);
  const [checkedState, setCheckedState] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const [nPages, setNPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentRecords, setCurrentRecords] = useState(0);
  const [emailTemplates, setEmailTemplates] = useState();
  const [sendingEmail, setSendingEmail] = useState(false);
  const [start, setStart] = useState(1);
  const [end, setEnd] = useState(11);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState(false);
  const [sendEmails, setSendEmails] = useState(false);
  const [fromDateValue, setFromDateValue] = useState<Date | undefined>(undefined);
  const [toDateValue, setToDateValue] = useState<Date | undefined>(undefined);
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [order, setOrder] = useState();

  const initialFormValues = {
    fromDate: fromDateValue,
    toDate: toDateValue
  };

  useEffect(() => {
    setLoading(true);

    const roles = getJwtTokenRoles();
    if (!roles.includes("FullAccess") && !roles.includes("ViewOrders")) {
      navigate('/401');
    }
    else {
      setSendEmails(roles.includes("SendEmails") || roles.includes("FullAccess"));
      const fetchData = async () => {
        await getOrdersAsync();

        const templates = await GetAllEmailTemplates();
        const mappedTemplates: any[] = [];
        templates.data.map((x: any) => {
          mappedTemplates.push({
            value: x.id, label: x.name
          })
        });
        setEmailTemplates(mappedTemplates);
        setLoading(false);
      };
      fetchData();
    }
  }, []);

  const getOrdersAsync = async (page?: any, start?: any, end?: any): any => {
    let pageNumber = page;
    if (currentPage == 1 && page == undefined) {
      pageNumber = 0;
    }

    await getOrders(pageNumber != undefined ? pageNumber : currentPage, recordsPerPage, searchTerm, start, end).then((result) => {
      if (result.status === 200) {
        let data = result.data.orders;
				let total = parseInt(result.data.count);

        setTotalRecords(Number.isNaN(total) ? 0 : total);
        setOrders(data);

        const indexOfLastRecord = currentPage * recordsPerPage;
        const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
        const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
        const pages = Math.ceil(total / recordsPerPage);
        setCurrentRecords(currentRecords);
        setNPages(pages);

        setCheckedState(new Array(data.length).fill(false));

      } else {
        setError(true);
        setMessage(result);
      }
    });
  };

  const handleSelectedPagination = async (currentPage?: any, page?: any) => {
    setLoading(true);

    if (page !== undefined && page > 1) {
      await getOrdersAsync(page);
      setCurrentPage(page);
      setStart(page);
      setEnd(page + 10);
    }
    else {
      await getOrdersAsync(currentPage);
      setStart(currentPage);
      setEnd(currentPage + 10);
    }
    setLoading(false);
  }

  const handleOnChange = (event: any, position: any, order: any) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    let orders = selectedOrders;
    event.target.checked
      ? orders.push(order)
      : orders = orders.filter(x => x.id !== order.id);

    setSelectedOrders(orders);
  };

  const selectAllCheckboxes = (e: any) => {
    if (e === true) {
      setCheckedState(checkedState.map(() => true));
    } else {
      setCheckedState(checkedState.map((item) => !item));
    }
  };

  const handleStatus = (status: string) => {
    status = status.toLowerCase();

    //klarna order statuses
    if (status === "klarna payment processing") {
      return styles.status.pending;
    }
    else if (status == "klarna payment successful") {
      return styles.status.succeeded;
    }
    //stripe order statuses
    else if (status == "succeeded") {
      return styles.status.succeeded;
    }
    else {
      return styles.status.pending;
    }
  };

  const handleShowConfirmEmailModal = (value: any) => {
    setMessage("");
    setShowConfirmEmailModal(value);
  }

  const handleEmailTemplate = (selected: any) => {
    setSelectedTemplate(selected);
    handleShowConfirmEmailModal(true);
  }

  const handleSendEmail = async () => {
    setSendingEmail(true);

    const data = selectedOrders.map((order: any) => {
      return {
        UserId: +order.userId,
        OrderId: order.id,
        EmailTemplateId: +selectedTemplate.value
      }
    });
    const result = await sendEmail(data);
    if (result.status === 200) {
      setMessage(`${data.length > 1 ? "Emails" : "Email"} sent successfully`);
    }
    setSendingEmail(false);
  }

  const handleSearch = async () => {
    setLoading(true);
    await getOrdersAsync(0);
    setLoading(false);
  }

  const TableCountLabel = () => {
    const text = loading ? "Fetching" : "Showing";

    return (
      <React.Fragment>
        {text} {" "}
        {(currentPage == 1 ? 1 : (currentPage - 1) * 10 + 1)}
        {" "}-{" "}
        {currentPage == 1 ? 10 : currentPage * 10} of {totalRecords}
      </React.Fragment>
    );
  }

  const setDateFromCallback = (date: string) => {
    if (date)
      setFromDateValue(new Date(date));
  }

  const setDateToCallback = (date: string) => {
    if (date)
      setToDateValue(new Date(date));
  }

  const handleDelivered = (order: any) => {
    setOrderDelivered({
        id: order.id
    }).then((result) => {
        if (result.status === 200) {
            order.delivered = true;
            setShowOrderModal(false);
        }
    })
}

  const handleFilter = async () => {
    setLoading(true);
    await getOrdersAsync(0, fromDateValue, toDateValue);
    setLoading(false);
  }

  const viewOrder = (order: any) => {
    setShowOrderModal(true);
    setOrder(order);
  }

  const formatOrderItemNotes = (values: any) => {
    // Split the comma-separated string into an array of values
    const valueArray = values.split(",");

    return (
      <div>
        {valueArray.map((value, index) => (
          <React.Fragment>
            {value && (
              <React.Fragment>
                - <span key={index}>{value.trim()}</span>
                <br />
              </React.Fragment>
            )}
          </React.Fragment>
        ))}
      </div>
    )
  };

  return (
    <PrimaryLayout topMenuChild={AccountTopMenu}>
      <Container py="40px" sx={styles.container}>

        <div className="main-wrapper">
          <div className="header" sx={styles.header}>
            <h1>Orders</h1>
            <hr className="bar-indent-center" />
          </div>

          <Formik
            initialValues={initialFormValues}
            enableReinitialize={true}
            onSubmit={async (values, { setSubmitting }) => {
            }}>
            {(formik: any) => {
              const { setFieldTouched } = formik;

              return (
                <div className="row">
                  <div className="col-lg-3 col-md-3">
                    <div className="form-group">
                      <label htmlFor="fromDate">
                        From
                      </label>
                      <DatePicker
                        name="fromDate"
                        id="fromDate"
                        className="form-control"
                        dateChangeCallback={setDateFromCallback}
                        setFieldTouchedCallback={setFieldTouched}
                      />
                    </div>
                  </div>

                  <div className="col-lg-3 col-md-3">
                    <div className="form-group">
                      <label htmlFor="toDate" >
                        To
                      </label>
                      <DatePicker
                        name="toDate"
                        id="toDate"
                        className="form-control"
                        dateChangeCallback={setDateToCallback}
                        setFieldTouchedCallback={setFieldTouched}
                      />
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-4">
                    <Button sx={styles.filterButton}
                      onClick={() => handleFilter()}
                      disabled={loading}>
                      Filter
                    </Button>
                  </div>
                </div>
              )
            }}
          </Formik>

          <div className="row" style={{ marginTop: '80px' }}>
            <div className="col-md-5 offset-md-7">
              <div className="search-wrapper" sx={styles.input.searchWrapper}>
                <GoSearch sx={styles.input.searchIcon} className="hidden" />{" "}
                <input
                  className="search"
                  type="text"
                  id="search"
                  name="search"
                  placeholder="Search Orders..."
                  sx={styles.input.searchBar}
                  onChange={(event) => {
                    setSearchTerm(event.target.value);
                  }}
                ></input>
                <Button sx={styles.input.searchButton}
                  onClick={() => handleSearch()}>
                  {!updating && <span></span>}
                  {updating && (
                    <Spinner animation="border" role="status">
                      <span className="sr-only"></span>
                    </Spinner>
                  )}
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-7">
          <p sx={styles.tableCountLabel}>
            <TableCountLabel />
          </p>
        </div>
        <div className="wrapper" sx={styles.tableWrapper}>

          {!loading ? (
            <React.Fragment>
              <section className="action-area">
                <div className="sendEmailModal">
                  <div className="modal" id="sendEmailModal">
                    <div className="modal-dialog modal-xl">
                      <div className="modal-content modal-dialog modal-xl">
                        <Modal
                          show={showConfirmEmailModal}
                          onHide={() => handleShowConfirmEmailModal(false)}
                          keyboard={true}
                          centered
                        >
                          <Modal.Header>
                            <Modal.Title>
                              <h3 sx={styles.modal.header}>Confirm</h3>
                              <IoIosClose
                                sx={styles.modal.close}
                                onClick={() => handleShowConfirmEmailModal(false)}
                              />
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body sx={styles.modal.body}>
                            Email Template:  <b>{selectedTemplate.label}</b>
                            <br />
                            Users:
                            <div sx={styles.modal.description}>
                              <ul>
                                {checkedState.map((item, index) =>
                                  item && (
                                    <li>{orders[index].name}</li>
                                  )
                                )}
                              </ul>
                            </div>
                          </Modal.Body>

                          <Modal.Footer>
                            <Button
                              sx={styles.buttonConfirm}
                              onClick={() => handleSendEmail()}>
                              {!sendingEmail ? (
                                <Text>Send</Text>
                              ) : (
                                <span sx={styles.buttonSpinner}>
                                  <Spinner animation="border" role="status">
                                    <span className="sr-only"></span>
                                  </Spinner>
                                </span>
                              )}
                            </Button>

                            <div className="col-lg-12 col-md-12">
                              {message && (
                                <p className={"text-center  " + (error ? 'text-danger' : 'text-success')}>
                                  {message}
                                </p>
                              )}
                            </div>
                          </Modal.Footer>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <table
                className="table"
                sx={styles.table}
                role="table"
                id="ordersTable"
              >
                <thead role="rowgroup" id="ordersTable">
                  <tr role="row" id="ordersTable">
                    <th>
                      <input
                        className="select-all"
                        type="checkbox"
                        id="selectAll"
                        name="select"
                        value="yes"
                        onChange={(e) => selectAllCheckboxes(e.target.checked)}
                      />
                    </th>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Order</th>
                    <th className="text-center">Ordered</th>
                    <th className="text-center">Due Date</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">Delivered</th>
                    <th>
                      <span sx={styles.right}>
                        Outstanding
                      </span>
                    </th>
                    <th className="text-center">View</th>
                  </tr>
                </thead>
                <tbody role="rowgroup" id="ordersTable">
                  {orders.map((order, index) => (
                    <tr role="row">
                      <td role="cell">
                        <input
                          className="checkbox"
                          type="checkbox"
                          id="select"
                          name="checkboxAll"
                          value="yes"
                          checked={checkedState[index]}
                          onChange={(x) => handleOnChange(x, index, order)}
                        ></input>
                      </td>
                      <td role="cell">{order.id}</td>
                      <td role="cell">{order.name}</td>
                      <td role="cell">
                        {order.orderItems.map((item: any) => (
                          <span>
                           <b>{`${item.name} x${item.quantity}`}</b>

                            {item?.notes && (
                              <React.Fragment>
                                
                                {formatOrderItemNotes(item.notes)}
                              </React.Fragment>
                            )}
                            <br />
                          </span>
                        ))}
                      </td>
                      <td className="text-center">{order.date}</td>
                      <td className="text-center">{order.dueDate}</td>
                      <td className="text-center">
                        <span
                          sx={handleStatus(order.status)}>
                          {order.status}
                        </span>
                      </td>
                      <td className="text-center" style={{ fontSize: '20px' }}>
                        {true && (
                          order.delivered ? (
                            <span style={{ color: '#639961' }}><AiOutlineCheckCircle /></span>
                          ) : (
                            <span style={{ color: '#dc3545' }}><AiOutlineCloseCircle /></span>
                          )
                        )}
                      </td>
                      <td className="text-right">
                        <span sx={styles.right}>
                          {order.balanceOutstanding}
                        </span>
                      </td>
                      <td className="text-center">
                        <CgArrowTopRightR style={{ fontSize: '25px', color: '#00d4c5' }} onClick={() => viewOrder(order)} />
                      </td>
                    </tr>
                  )
                  )}
                </tbody>
              </table>

              {sendEmails && (
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3">
                      <Select
                        name="emailTemplate"
                        id="emailTemplate"
                        classNamePrefix="react-select"
                        options={emailTemplates}
                        onChange={(x) => handleEmailTemplate(x)}
                        isDisabled={!checkedState.includes(true)}
                        sx={styles.emailTemplateSelect}
                      />
                    </div>
                    <div className="col-md-9">
                      <p style={{ float: "right" }}>
                        <TableCountLabel />
                      </p>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-md-12" style={{ marginTop: '20px' }}>
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  handleSelectedPagination={handleSelectedPagination}
                  start={start}
                  end={end}
                />
              </div>
            </React.Fragment>
          ) : (
            <span sx={styles.spinner}>
              <Spinner animation="border" role="status">
                <span className="sr-only"></span>
              </Spinner>
            </span>
          )}
        </div>
      </Container>

      <Modal
        show={showOrderModal}
        onHide={() => setShowOrderModal(false)}
        keyboard={true}
        centered
      >
        <Modal.Header>
          <Modal.Title>
            Order Number: {order?.id}
            <IoIosClose sx={styles.modal.close} onClick={() => setShowOrderModal(false)} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          {order?.status && (
            <React.Fragment>
            <span style={{textAlign: 'center', display: 'block'}}
              sx={handleStatus(order.status)}>
              {order.status}
            </span>
            <br/>
            </React.Fragment>
          )}

          <Accordion preExpanded={["orderItems"]}>

            <AccordionItem uuid={"orderItems"}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h6>Order Items</h6>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div sx={styles.modal.description} >
                  <ul sx={styles.products}>
                    {order?.orderItems?.map((orderItem: any) => (
                      <li sx={styles.products.item}>

                        <b>{`${orderItem.name} x${orderItem.quantity}`}</b>

                        {orderItem?.notes && (
                          <React.Fragment>
                            {formatOrderItemNotes(orderItem.notes)}
                          </React.Fragment>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              </AccordionItemPanel>
            </AccordionItem>

            {order?.courierName && (
              <AccordionItem uuid={"courier"}>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h6>Courier & Hospital</h6>
                  </AccordionItemButton>
                </AccordionItemHeading>
              <AccordionItemPanel>

                {order?.hospitalAddress && (
                  <React.Fragment>
                    <b>Hospital</b>
                    <br /><br />
                    <b>Address Line 1:</b> {order?.hospitalAddress?.addressLine1}<br />
                    <b>Address Line 2:</b> {order?.hospitalAddress?.addressLine2}<br />
                    {order?.hospitalAddress?.addressLine3 && (
                      <React.Fragment>
                        <b>Address Line 3:</b> {order?.hospitalAddress?.addressLine3}<br />
                      </React.Fragment>
                      )}
                      <b>City:</b> {order?.hospitalAddress?.city}<br />
                      <b>County:</b> {order?.hospitalAddress?.county}<br />
                      <b>Postcode:</b> {order?.hospitalAddress?.postalCode}<br />
                      <b>Country</b>: {order?.hospitalAddress?.country}<br />
                      <br />
                    </React.Fragment>
                  )}

                  <b>Courier</b>
                  <br /><br />
                  <b>Type</b>: {order?.courierName}<br />
                  <b>Number</b>: {order?.courierNumber}<br />
                  <hr />
                  <b>Sub Total</b>: {order?.courierPrice}<br />
                  <b>VAT (£)</b>: {order?.courierVatAmount}<br />
                  <b>VAT (%)</b>: {order?.totalCourierVat}<br />                
                  <b>Total Price</b>: {order?.totalCourierPrice}

                </AccordionItemPanel>
              </AccordionItem>
              )}
            <AccordionItem uuid={"payments"}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h6>Payments</h6>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                {order?.payments.map(
                  (payment: any, index: number) => (
                    <div key={index} className="container">
                      <div className="row">
                        <div className="col-md-5">
                          {payment.date == "Pending"
                            ? (<b>{payment.total}</b>)
                            : (<span>{payment.total}</span>)}
                        </div>
                        <div className="col-md-6">
                          {payment.status && payment.status !== "Succeeded" ? (
                            <b>{payment.status}</b>
                          ) : (
                            payment.date == "Pending" ? (
                              <span>{payment.date}</span>
                            ) : (
                              <span>{payment.date}</span>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  )
                )}

                {order?.balanceOutstanding ? (
                  <React.Fragment>
                    <br />
                    <span style={{ color: 'red' }}>Outstanding Balance: {order?.balanceOutstanding}</span>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <br />
                    <b>The entire balance has been paid.</b>
                  </React.Fragment>
                )}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid={"contact"}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h6>Customer</h6>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <b>Name:</b> {order?.name}<br />
                <b>Phone:</b> {order?.phone}<br />
                <b>Email:</b> {order?.email}

               <hr />
                <b>Address:</b> {order?.customerAddress?.addressLine1}<br />
                <b>City:</b> {order?.customerAddress?.city}<br />
                <b>County:</b> {order?.customerAddress?.county}<br />
                <b>Postcode:</b> {order?.customerAddress?.postalCode}<br />
                <b>Country</b>: {order?.customerAddress?.country}<br />               
              </AccordionItemPanel>
            </AccordionItem>


            <AccordionItem uuid={"Delivery"}>
              <AccordionItemHeading>
                <AccordionItemButton>
                <h6>Delivery</h6>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
              <b>Name:</b> {order?.name}<br />
              <b>Address:</b> {order?.deliveryAddress?.addressLine1}<br />
                <b>City:</b> {order?.deliveryAddress?.city}<br />
                <b>County:</b> {order?.deliveryAddress?.county}<br />
                <b>Postcode:</b> {order?.deliveryAddress?.postalCode}<br />
                <b>Country</b>: {order?.deliveryAddress?.country}<br />    

                {order?.deliveryNotes && (
                  <div sx={styles.modal.description}>
                    <hr className="bar-indent"></hr>
                    <h6>Delivery Notes</h6>
                    {order?.deliveryNotes}
                  </div>
                )}
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem uuid={"medical"}>
              <AccordionItemHeading>
                <AccordionItemButton>
                  <h6>Medical Form</h6>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <button sx={styles.button} onClick={() => {
                  GetMedicalPdf(order.userId).then((result) => {
                    console.log(result.data)
                    const url = window.URL.createObjectURL(result.data);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `Medical-Form-${order.userId}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                  })
                }}>Download Medical Form</button>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>


        </Modal.Body>
        <Modal.Footer sx={styles.modal.footer}>
          <Button
            sx={styles.modal.button}
            title="Set Order Delivered"
            disabled={order?.delivered}
            onClick={() => handleDelivered(order)}
          >
            {order?.delivered ? (
              <Fragment>
                Order Delivered
              </Fragment>
            ) : (
              <Fragment>
                Set Delivered
              </Fragment>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </PrimaryLayout>
  );
};

const styles = {
  products: {
    paddingLeft: '10px',
    item: {
      width: 'max-content',
    }
  },
  orderButton: {
    fontSize: '25px!important'
  },
  tableCountLabel: {
    marginTop: '-27px',
    "@media only screen and (max-width: 768px)": {
      display: 'none'
    },
  },
  emailTemplateSelect: {
    fontSize: '13px'
  },
  spinner: {
    textAlign: "center",
    padding: "20px",
    display: "block",
    ".spinner-border": {
      height: "4em",
      width: "4em",
      color: "#00d4c5",
    },
  },
  buttonSpinner: {
    textAlign: "center",
    display: "block",
    ".spinner-border": {
      color: "white",
    },
  },
  right: {
    float: 'right'
  },
  status: {
    pending: {
      background: "#f8d7da",
      borderColor: "#f5c6cb",
      color: "#721c24",
      padding: "5px",
      borderRadius: "4px",
    },
    succeeded: {
      background: "#d4edda",
      borderColor: "##c3e6cb",
      color: "#155724",
      padding: "5px",
      borderRadius: "4px",
    },
  },
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
    wrapper: {
      border: "1px solid #e4e4e4",
      boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
      transition:
        "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
      borderRadius: "6px",
      background: "white",
      position: "relative",
      margin: "0 auto",
      "@media only screen and (max-width: 768px)": {
        padding: 20,
      },
    },

    tableWrapper: {
      "@media only screen and (max-width: 1200px)": {
        overflowX: "scroll",
      },
      maxWidth: "1200px",
    },

    footer: {
      display: "unset",
    },

    products: {
      paddingLeft: "10px",
      item: {
        width: "max-content",
      },
    },
    error: {
      margin: "0 auto",
      fontSize: "14px",
    },
    headerWrapper: {
      padding: "40px 30px 0 30px",
      "@media only screen and (max-width: 768px)": {
        padding: "30px 10px",
        margin: "30px auto",
      },
    },
    header: {
      textAlign: "center",
    },
    modal: {
      description: {
        maxHeight: "200px",
        overflowY: "scroll",
      },
      close: {
        position: "absolute",
        right: "3px",
        fontSize: "40px",
        top: "5px",
        cursor: "pointer",
      },
      footer: {
        width: "100%",
        margin: "0 auto",
        display: "block",
      },
      title: {
        fontSize: "17px",
        margin: 0,
      },
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
  },
  checkbox: {
    width: "5%",
    wordWrap: "break-Word",
  },
  id: {
    width: "10%",
    wordWrap: "break-Word",
  },
  date: {
    width: "10%",
    wordWrap: "break-Word",
  },
  balance: {
    width: "20%",
    wordWrap: "break-Word",
  },
  header: {
    textAlign: "center",
  },
  selected: {
    border: "1px solid #e4e4e4",
    boxShadow: "0 2px 45px 0 rgb(0 0 0 / 6%)",
    transition:
      "background 0.3s,border 0.3s,border-radius 0.3s,box-shadow 0.3s",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    background: "white",
    maxWidth: "400px",
    marginTop: "3rem",
    margin: "40 auto",
    position: "relative",
    "@media only screen and (max-width: 768px)": {
      padding: "20px 20px",
      margin: "10px auto",
    },
  },
  selectedcontent: {
    display: "flex",
    justifyContent: "center",
  },
  item: {
    cursor: "pointer",
    "list-style-type": "none",
    textAlign: "left",
    width: "100%",
    border: "3px solid #eeee",
    margin: "6px",

    ":hover": {
      opacity: ".9",
    },
    ":active": {
      backgroundColour: "#8b0000",
    },
  },
  content: {
    backgroundColor: "white",
    color: "black",
    overflow: "hidden",
    paddingLeft: "18px",

    margin: "6px",

    "&.show": {
      display: "none",
    },
  },
  wrapper: {
    marginTop: "5rem",
    maxWidth: "600px",
    border: "1px solid #eeee",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
    "@media only screen and (max-width: 768px)": {
      padding: "20px 20px",
      margin: "10px auto",
      marginTop: "2rem",
    },
  },

  sectionHeading: {
    marginBottom: "1.5rem",
  },
  buttonDanger: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#DC3545",
    width: "100%",
    textTransform: "uppercase",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#DC3545",
      borderColor: "#DC3545",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
  modal: {
    button: {
      zIndex: '9999!important',
      width: '100%',
      textDecoration: 'unset',
      textTransform: 'uppercase',
      display: 'flex',
      opacity: 1,
      transform: 'scale(1)',
      '&:disabled': {
        opacity: 0.6,
        cursor: 'not-allowed',
        backgroundColor: "#E6E6E6",
        borderColor: "#E6E6E6",
        color: "#878788",
        fontWeight: 500
      },
      ':hover': {
        opacity: '.9'
      },
      backgroundColor: '#00d4c5',
      fontWeight: '400',
      fontSize: '13px',
      color: 'white',
      borderRadius: '10px',
      padding: 0,
      icon: {
        marginTop: '-6px',
        color: 'white',
        fontSize: 15
      },
      link: {
        lineHeight: '40px',
        margin: '0 auto'
      },
    },
    header: {
      textAlign: "center",
    },
    footer: {
      width: '100%',
      margin: '0 auto',
      display: 'block'
    },
    close: {
      position: "absolute",
      right: "3px",
      fontSize: "40px",
      top: "5px",
      cursor: "pointer",
    },
    description: {
      maxHeight: '250px',
      overflowY: 'scroll'
    },
    title: {
      fontSize: "17px",
      margin: 0,
    },
    body: {
      lineHeight: "2.5rem",
    },
  },
  filter: {
    color: "#878788",
  },
  tableWrapper: {
    '@media only screen and (max-width: 1206px)': {
      overflowX: 'scroll'
    },
    marginTop: "20px",
    border: "1px solid #eeee",
    padding: "30px 40px 30px 40px",
    borderRadius: "6px",
    boxShadow: "0 2px 4px rgba(41, 41, 41, 0.06)",
    "@media only screen and (max-width: 768px)": {
      padding: "20px 20px",
      margin: "10px auto",
      marginTop: "2rem",
    },
  },
  button: {
    color: "white",
    padding: "5px",
    borderRadius: "4px",
    background: "rgb(35, 179, 163)",
    border: "rgb(35, 179, 163)",
    fontSize: '13px',
    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#00D4C5",
      borderColor: "#00D4C5",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
    order: {
      color: "white",
      padding: "0",
      borderRadius: "4px",
      background: "rgb(35, 179, 163)",
      border: "rgb(35, 179, 163)",
      "&:disabled": {
        opacity: 0.6,
        cursor: "not-allowed",
        backgroundColor: "#00D4C5",
        borderColor: "#00D4C5",
        color: "#878788",
        fontWeight: 500,
      },
      ":hover": {
        opacity: ".9",
      },
    }
  },
  buttonConfirm: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#00D4C5",
    width: "100%",
    textTransform: "uppercase",

    "&:disabled": {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "#00D4C5",
      borderColor: "#00D4C5",
      color: "#878788",
      fontWeight: 500,
    },
    ":hover": {
      opacity: ".9",
    },
  },
  input: {
    searchWrapper: {
      display: "flex",
      alignItems: "center",
      border: "1px solid #E5E5E5",
      borderRadius: "8px",
      textIndent: '5px',
      "@media only screen and (max-width: 768px)": {
        width: "100%",
        display: "block",
      },
      float: 'right'
    },
    searchBar: {
      width: "100%",
      paddingRight: "40px",
      marginLeft: "30px",
      border: "0px",
      borderStyle: "solid",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      borderColor: "#E4E4E4",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "darkgray",
      },
      "@media only screen and (max-width: 768px)": {
        width: "80%",
        display: "block",
        padding: ".8rem",
        marginLeft: "24px",
      },
    },
    searchIcon: {
      position: "absolute",
      marginLeft: "8px",
      fontSize: "18px",
      "@media only screen and (max-width: 768px)": {
        fontSize: "22px",
        position: "absolute",
        marginLeft: "8px",
        marginTop: "0.8rem",
      },
    },
    searchButton: {
      backgroundColor: "#00d4c5",
      fontSize: "14px",
      fontWeight: "600",
      textTransform: "uppercase",
      borderRadius: "0    6px      6px           0",
      "@media only screen and (max-width: 768px)": {
        width: "100%",
        borderRadius: "0 0  6px 6px",
      },
    },
  },
  pagination: {
    justifyContent: "center",
    '.pagination': {
      float: 'right!important'
    }
  },
  filterButton: {
    backgroundColor: "#00d4c5",
    fontSize: "14px",
    fontWeight: "600",
    textTransform: "uppercase",
    borderRadius: "6px",
    marginTop: '21px',
    minHeight: '0!important'
  }
};

export default AccountOrderPage;
